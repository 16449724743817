import { Alert, Autocomplete, Button, Dialog,  DialogActions, DialogContent, DialogTitle, Snackbar, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

export default function CreateUser() {

  const roles = 
  [
    'Normal', 'Admin', 'Super Admin'
  ];
  const[Info, setInfoState] = useState(false)
  const[alert, setAlert] = useState(false)
  const [error, setError] = useState(false)
  const [role, setRole] = useState<string | null>(roles[0]);
  const[popupstate, setPopupstate] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
    const handleSubmit =  () => {
    const permission = role ? roles.indexOf(role) : null; 
    const data = {
      Username: username,
      Password: password,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Role: {
        Name: role,
        permissions: permission
      }
    }
    console.log(data)
    if(!validPassword()){
      return
    }
    postUser(data)
  }

  const handleClose = () => {
    setPopupstate(false)
    setError(false)
   
  }
  const validPassword = () => {
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(.{8,})$/;
    regex.test(password) ? setError(false) : setError(true);
    return regex.test(password)
  }
  function postUser(data: any){
      fetch("https://localhost:7286/Admin/CreateUser", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.status === 200) {
          setPopupstate(false);
          setInfoState(true)
        }
        else{
          setAlert(true);
        }
        
      })
      .catch((error) => {
       setAlert(true)
        console.error('Error posting data:', error);
      });
    }
   return(<div>
         <Button variant='contained' onClick={(event) => setPopupstate(true)} sx={{ml: 10, mr:10, backgroundColor: 'green'}} >Create User</Button>
          
          <div>
           <Dialog open={popupstate} onClose={handleClose}>
              <DialogTitle>Create a new User</DialogTitle>
              <DialogContent>
                <Stack spacing={5} direction={'row'} sx={{mt: 1}}>
              <Stack spacing={2} direction={'column'} >
                  <TextField required variant='outlined' label="New Username" onChange={(event) => setUsername(event.target.value)}></TextField>
                  <TextField error={error} required variant='outlined' type='password' label="Password" onChange={(event) => setPassword(event.target.value)}></TextField>
                  <TextField required variant='outlined' type='email' label="Email" onChange={(event) => setEmail(event.target.value)}></TextField>
                  
                 </Stack>
                 <Stack spacing={2} direction={'column'}>
                   <TextField required variant='outlined' onChange={(event) => setFirstName(event.target.value)} label="First Name" ></TextField>
                   <TextField required variant='outlined' onChange={(event) => setLastName(event.target.value)} label="Last Name"></TextField>
                   <TextField required variant='outlined' onChange={(event) => setPhone(event.target.value)} label="Phone"></TextField>
                 </Stack>
                </Stack>
                 <Stack>
                 <Autocomplete defaultValue={null} value={role} onChange={(event: any, newRole: string | null)=> {
                    setRole(newRole);
                 }}
                 id="tags-roles"
                 options={roles}
                 renderInput={(params) => <TextField sx={{mt: 2}} {...params} label="Choose Role"></TextField>}
                 ></Autocomplete>
                 </Stack>
              </DialogContent>
              <DialogActions>
                  <Stack spacing={2.2} style={{paddingRight: 180}} direction={'row'}>
                    <Button variant='contained' type='submit' color='success' onClick={handleSubmit}>Save</Button>
                    <Button variant='contained' onClick={(event) => setPopupstate(false)} color='error'>Close</Button>
                  </Stack>
                </DialogActions>
           </Dialog>
          </div>
          <Snackbar open={Info} onClose={(event) => setInfoState(false)} autoHideDuration={3000}>
        <Alert severity='success' sx={{width: '100%', fontFamily: 'sans-serif'}}>User created Succesfully</Alert>
      </Snackbar>
      <Snackbar open={alert} onClose={(event) => setAlert(false)} autoHideDuration={3000}>
        <Alert severity='error' sx={{width: '100%', fontFamily: 'sans-serif'}}>There was a Problem creating this User</Alert>
      </Snackbar>
    </div>)
}