import { AppBar, Avatar, Toolbar } from '@mui/material';
import React, { ReactNode } from 'react';
import Profile from './Menu';

interface AppBarProps {
  children?: ReactNode; // This allows any ReactNode as children
}
const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right' ,
    
  },
};
const CustomAppBar: React.FC<AppBarProps> = ({children}) => {
  return (
    <AppBar >
    <Toolbar style={styles.root}>
       <Profile></Profile>
       {children}
    </Toolbar>
</AppBar>  );
};

export default CustomAppBar;
