import { Avatar, Card, CardActionArea, CardContent, CardMedia,Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OnBoarding from './Onboarding';
import Offboarding from './OffBoarding';
import { useEffect } from 'react';
import AppBar from './MenuAppBar';
import CustomAppBar from './MenuAppBar';
export default function Home(){
    const logo = require("./react-pics/velocelogooderso.png");
    let navigate  = useNavigate();
    useEffect(() => {
   
    }, [])
    
    return(
      <>
  
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
     <CustomAppBar></CustomAppBar>
    <Card sx={{ maxWidth: 250, ml: 10, mt: 20 }}>
      <CardActionArea onClick={() => navigate("/adminveloce")}>
        <CardMedia component="img" height="250" image={logo} alt="veloce logo"/>
        <CardContent sx={{backgroundColor: 'black'}}>
          <Typography sx={{color: 'white'}} gutterBottom variant="h5" component="div">admin.veloce.at</Typography>
        </CardContent>
      </CardActionArea>
    </Card>

   <OnBoarding></OnBoarding>
   <Offboarding></Offboarding>
   
  </div>
  </>
    )
}